import { useStorage } from "@vueuse/core"
import { isKeyOf } from "@/utils/object"

const FLAG_DEFAULTS: Record<string, boolean> = {
  honeycomb_demo_functions: false,
}

// return value of feature flag <flag> or false if it doesn't exist
export function useFeatureFlag(flag: string): boolean {
  const flags = useFeatureFlags()
  if (isKeyOf(flags.value, flag)) return flags.value[flag]
  return false
}

// return full list of feature flags with current state
export function useFeatureFlags() {
  return useStorage("sf-feature-flags", FLAG_DEFAULTS, localStorage, {
    mergeDefaults: true,
  })
}
